import C from "./constants";
import { combineReducers } from "redux";

const borders = (state = [], action) => {
  switch (action.type) {
    case C.SET_BORDER:
      return action.payload;
    case C.REMOVE_BORDER:
      return [];
    default:
      return state;
  }
};

const page_dimension = (state = "", action) => {
  switch (action.type) {
    case C.SET_PAGE_DIMENSION:
      return action.payload;
    case C.REMOVE_PAGE_DIMENSION:
      return "";
    default:
      return state;
  }
};
const header_text = (state = "", action) => {
  switch (action.type) {
    case C.SET_HEADER:
      return action.payload;
    case C.CLEAR_HEADER:
      return "";
    default:
      return state;
  }
};
const initIntro = {
  title: "",
  text: "",
  titleFontSize: 16,
  titleFontFamily: "Arial",
  textFontSize: 18,
  textFontFamily: "Arial",
  titleAlignment: "LEFT",
  alignment: "LEFT"
};
const intro = (state = initIntro, action) => {
  switch (action.type) {
    case C.SET_INTRO:
      return action.payload;
    case C.REMOVE_INTRO:
      return initIntro;
    default:
      return state;
  }
};
const closingRemarksInitState = {
  title: "",
  text: "",
  titleFontSize: 16,
  titleFontFamily: "Arial",
  textFontSize: 18,
  textFontFamily: "Arial",
  titleAlignment: "LEFT",
  alignment: "LEFT"
};
const closingRemarks = (state = closingRemarksInitState, action) => {
  switch (action.type) {
    case C.SET_CLOSING_REMARKS:
      return action.payload;
    case C.CLEAR_CLOSING_REMARKS:
      return closingRemarksInitState;
    default:
      return state;
  }
};
const lineImage = (state = null, action) => {
  switch (action.type) {
    case C.SET_LINE_IMAGE:
      return action.payload;
    case C.REMOVE_LINE_IMAGE:
      return null;
    default:
      return state;
  }
};
export const ads = (state = [], action) => {
  switch (action.type) {
    case C.ADD_AD:
      return [...state, action.payload];
    case C.REMOVE_AD:
      return state.filter(ad => ad.id !== action.payload);
    case C.SET_ADS:
      return action.payload;
    default:
      return state;
  }
};
export const question = (state = [], action) => {
  switch (action.type) {
    case C.ADD_QUESTION:
      return [...action.payload, ...state];
    case C.UPDATE_QUESTION:
      return state.map(question => {
        return question.id !== action.payload.id ? question : action.payload;
      });
    case C.REMOVE_QUESTION:
      return state.filter(question => question.id !== action.payload);
    case C.SET_QUESTIONS:
      return action.payload;
    case C.ADD_TIP:
      return state.map(question => {
        return question.id !== action.payload.topic_id
          ? question
          : {
              ...question,
              tips: [action.payload, ...question.tips]
            };
      });
    case C.REMOVE_TIP:
      return state.map(question => {
        return question.id !== action.payload.topic_id
          ? question
          : {
              ...question,
              tips: question.tips.filter(tip => tip.id !== action.payload.id)
            };
      });
    case C.UPDATE_TIP:
      return state.map(question => {
        return question.id !== action.payload.topic_id
          ? question
          : {
              ...question,
              tips: question.tips.map(tip =>
                tip.id !== action.payload.id ? tip : action.payload
              )
            };
      });
    default:
      return state;
  }
};
export const clipBoardQuestion = (state = [], action) => {
  switch (action.type) {
    case C.ADD_CB_QUESTION:
      return [...state, ...action.payload];
    case C.UPDATE_CB_QUESTION:
      return state.map(question => {
        return question.id !== action.payload.id ? question : action.payload;
      });
    case C.REMOVE_CB_QUESTION:
      return state.filter(question => question.id !== action.payload);
    case C.SET_CB_QUESTIONS:
      return action.payload;
    default:
      return state;
  }
};
export const category = (state = [], action) => {
  switch (action.type) {
    case C.ADD_CATEGORY:
      return [...state, action.payload];
    case C.SET_CATEGORY:
      return action.payload;
    case C.UPDATE_CATEGORY:
      return state.map(category => {
        return category.id !== action.payload.id ? category : action.payload;
      });
    case C.REMOVE_CATEGORY:
      return state.filter(category => category.id !== action.payload);
    default:
      return state;
  }
};

export const domain_resourses = (state = null, action) => {
  switch (action.type) {
    case C.ADD_DOMAIN_RESOURCE:
      return [...state, ...action.payload];
    case C.SET_DOMAIN_RESOURCE:
      return action.payload;
    case C.MERGE_DOMAIN_RESOURCE:
      return [...state, ...action.payload];
    case C.REMOVE_DOMAIN_RESOURCE:
      return state.filter(resource => resource.id !== action.payload);
    default:
      return state;
  }
};

export const user = (state = null, action) => {
  switch (action.type) {
    case C.SET_USER:
      return action.payload;
    case C.REMOVE_USER:
      return null;
    default:
      return state;
  }
};
export const allUsers = (state = [], action) => {
  switch (action.type) {
    case C.SET_ALL_USERS:
      return action.payload;
    case C.ADD_SINGLE_USER:
      return [action.payload, ...state];
    case C.REMOVE_SINGLE_USER:
      return state.filter(user => user.id !== action.payload);
    case C.REMOVE_ALL_USERS:
      return null;
    case C.UPDATE_ALL_USERS:
      return state.map(user => {
        return user.id !== action.payload.id ? user : action.payload;
      });
    default:
      return state;
  }
};
export const apiKey = (state = null, action) => {
  switch (action.type) {
    case C.SET_API_KEY:
      return action.payload;
    case C.CLEAR_API_KEY:
      return null;
    default:
      return state;
  }
};

export const adminApiKey = (state = null, action) => {
  switch (action.type) {
    case C.SET_ADMIN_API_KEY:
      return action.payload;
    case C.CLEAR_ADMIN_API_KEY:
      return null;
    default:
      return state;
  }
};
export const errors = (state = [], action) => {
  switch (action.type) {
    case C.ADD_ERROR:
      return [...state, action.payload];
    case C.CLEAR_ERROR:
      return state.filter((message, index) => index !== action.payload);
    default:
      return state;
  }
};

let initPrefs = {
  rowsPerPage: 100
};
export const userPrefs = (state = initPrefs, action) => {
  switch (action.type) {
    case C.SET_PREFS:
      return action.payload;
    default:
      return state;
  }
};

const appReducer = combineReducers({
  borders,
  intro,
  closingRemarks,
  page_dimension,
  header_text,
  lineImage,
  adminApiKey,
  apiKey,
  user,
  allUsers,
  category,
  domain_resourses,
  question,
  clipBoardQuestion,
  ads,
  errors,
  userPrefs
});
// const rootReducer = (state, action) => {
//   if (action.type === C.USER_LOGOUT) {
//     state = undefined;
//   }
//   return appReducer(state, action);
// };

export default appReducer;
//export default rootReducer;
