import C from "./constants";
import axios from "axios";
import Cookies from "universal-cookie";

export const BASE_URL =
  //Base urls
  // "http://localhost/Projects/Amy/quickTipComposer_api";
  "https://tipsheet.rebrandablecontent.com/api";

export const getBorders = () => dispatch => {
  axios
    .get(BASE_URL + "/borders", {
      params: {
        timeStamp: new Date().getTime()
      }
    })
    .then(response => {
      dispatch({ type: C.SET_BORDER, payload: response.data });
    })
    .catch(error => {
      console.log(error);
    });
};
export const getBorderImage = path => {
  return axios
    .get(BASE_URL + "/border_img", {
      responseType: "arraybuffer",
      params: {
        path: path
      }
    })
    .then(response => {
      return new Buffer(response.data, "binary").toString("base64");
    });
};
export const setIntro = intro => {
  return {
    type: C.SET_INTRO,
    payload: intro
  };
};

export const setClosingRemarks = remarks => {
  return {
    type: C.SET_CLOSING_REMARKS,
    payload: remarks
  };
};

export const setPageDimensions = value => {
  return {
    type: C.SET_PAGE_DIMENSION,
    payload: value
  };
};
export const removeUser = user => (dispatch, getState) => {
  axios
    .delete(BASE_URL + "/users/" + user.id, {
      headers: { Authorization: `Bearer ${getState().apiKey}` }
    })
    .then(response => {
      if (response.status === 204) {
        dispatch({
          type: C.REMOVE_SINGLE_USER,
          payload: user.id
        });
      }
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};

const getLineImage = url => {
  return axios
    .get(url, {
      responseType: "arraybuffer"
    })
    .then(response => new Buffer(response.data, "binary").toString("base64"));
};
const getCategories = jwt => {
  let config = {
    headers: {
      Authorization: "Bearer " + jwt
    }
  };
  return axios.get(BASE_URL + "/category", config);
};
const getResources = () => {
  return axios.get(BASE_URL + "/resources");
};
const getAds = () => {
  return axios.get(BASE_URL + "/ads");
};
const getQuestions = jwt => {
  let config = {
    headers: {
      Authorization: "Bearer " + jwt
    }
  };
  return axios
    .get(BASE_URL + "/questions", config)
    .catch(error => console.log(error.response));
};

export const addError = error => {
  return {
    type: C.ADD_ERROR,
    payload: error
  };
};
export const verifyAccount = (username, apikey) => {
  return axios.get(BASE_URL + "/verify", {
    params: {
      username: username,
      apikey: apikey
    }
  });
};
export const recoverPassword = username => {
  return axios.get(BASE_URL + "/forgot_password", {
    params: {
      username: username
    }
  });
};
export const resetPassword = (username, code, password) => {
  return axios.get(BASE_URL + "/reset_password", {
    params: {
      username: username,
      code: code,
      password: password
    }
  });
};
export const addSingleUser = user => dispatch => {
  dispatch({
    type: C.ADD_SINGLE_USER,
    payload: user
  });
};
export const addUser = (name, username, password, apiKey) =>
  axios.post(
    BASE_URL + "/add_user",
    {
      name: name,
      username: username,
      password: password
    },
    {
      headers: { Authorization: `Bearer ${apiKey}` }
    }
  );
export const getAllUsers = jwt => {
  let config = {
    headers: {
      Authorization: "Bearer " + jwt
    }
  };
  return axios.get(BASE_URL + "/users", config);
};
export const updateUserType = (user_id, active, verified) => (
  dispatch,
  getState
) => {
  //console.log(user_id, active, verified);
  axios
    .post(
      BASE_URL + "/users/" + user_id,
      {
        active: active,
        verified: verified
      },
      {
        headers: { Authorization: `Bearer ${getState().apiKey}` }
      }
    )
    .then(response => {
      dispatch({
        type: C.UPDATE_ALL_USERS,
        payload: response.data
      });
    })
    .catch(error => {
      addError(error);
    });
};
export const getInitData = () => (dispatch, getState) => {
  if (getState().user === null) {
    return;
  }
  getLineImage(BASE_URL + "/fileimg").then(response => {
    dispatch({
      type: C.SET_LINE_IMAGE,
      payload: response
    });
  });
  if (getState().user.type === "admin") {
    getAllUsers(getState().apiKey).then(response => {
      dispatch({
        type: C.SET_ALL_USERS,
        payload: response.data
      });
    });
  }
  getCategories(getState().apiKey)
    .then(response => {
      var categories = response.data;
      dispatch({
        type: C.SET_CATEGORY,
        payload: categories
      });
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
  getQuestions(getState().apiKey)
    .then(response => {
      let questions = response.data.map(question => {
        let tips = question.tips.map(tip => ({ ...tip, drag_id: tip.id + "" }));
        return { ...question, tips: tips };
      });

      dispatch({
        type: C.SET_QUESTIONS,
        payload: questions
      });
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
  getResources().then(response => {
    dispatch({
      type: C.SET_DOMAIN_RESOURCE,
      payload: response.data
    });
  });
  getAds().then(response => {
    dispatch({
      type: C.SET_ADS,
      payload: response.data
    });
  });
};
export const setHeader = headerName => {
  return {
    type: C.SET_HEADER,
    payload: headerName
  };
};
export const clearHeader = () => {
  return {
    type: C.CLEAR_HEADER
  };
};
export const login = (username, password) => {
  return axios.get(BASE_URL + "/login", {
    params: {
      username: username,
      password: password
    }
  });
};
export const register = (name, username, password) => {
  return axios.get(BASE_URL + "/register", {
    params: {
      name: name,
      username: username,
      password: password
    }
  });
};
export const setApikey = apiKey => dispatch => {
  const cookies = new Cookies();
  cookies.set("apiKey", apiKey, {
    path: "/",
    sameSite: "strict"
  });
  dispatch({
    type: C.SET_API_KEY,
    payload: apiKey
  });
};
export const setUser = user => dispatch => {
  const cookies = new Cookies();
  cookies.set("user", user, {
    path: "/",
    sameSite: "strict"
  });
  dispatch({
    type: C.SET_USER,
    payload: user
  });
};
export const logout = () => dispatch => {
  const cookies = new Cookies();
  cookies.remove("apiKey", { path: "/", sameSite: "strict" });
  cookies.remove("user", { path: "/", sameSite: "strict" });
  dispatch({
    type: C.CLEAR_API_KEY,
    payload: null
  });
  dispatch({
    type: C.REMOVE_USER,
    payload: null
  });
};
export const upDateAd = (name, url, type, file) => dispatch => {
  const data = new FormData();
  data.append("file", file);
  data.append("name", name);
  data.append("url", url);
  data.append("type", type);
  axios.post(BASE_URL + "/ads", data).then(response => {
    dispatch({
      type: C.SET_ADS,
      payload: response.data
    });
  });
};
export const addCategory = (name, parent_id, description, file) => (
  dispatch,
  getState
) => {
  const data = new FormData();
  data.append("file", file);
  data.append("name", name);
  data.append("parent_id", parent_id);
  data.append("description", description);
  axios
    .post(BASE_URL + "/category", data, {
      headers: { Authorization: `Bearer ${getState().apiKey}` }
    })
    .then(response => {
      dispatch({
        type: C.ADD_CATEGORY,
        payload: response.data
      });
    });
};

export const updateCategory = (id, name, parent_id, description, file) => (
  dispatch,
  getState
) => {
  const data = new FormData();
  data.append("file", file);
  data.append("name", name);
  data.append("parent_id", parent_id);
  data.append("description", description);
  axios
    .post(BASE_URL + "/category/" + id, data, {
      headers: { Authorization: `Bearer ${getState().apiKey}` }
    })
    .then(response => {
      dispatch({
        type: C.UPDATE_CATEGORY,
        payload: response.data
      });
    });
};

export const removeCategory = id => (dispatch, getState) => {
  axios
    .delete(BASE_URL + "/category/" + id, {
      headers: { Authorization: `Bearer ${getState().apiKey}` }
    })
    .then(response => {
      if (response.status === 204) {
        dispatch({
          type: C.REMOVE_CATEGORY,
          payload: id
        });
      }
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};
export const bulkUploadResource = (resources, category_id) => dispatch => {
  axios
    .post(BASE_URL + "/resources_bulk", {
      category_id: category_id,
      resources: resources
    })
    .then(response => {
      dispatch({
        type: C.ADD_DOMAIN_RESOURCE,
        payload: response.data
      });
    })
    .catch(error => {
      //console.log(error.message);
      dispatch(addError(error.message));
    });
};

export const bulkUploadQuestions = (questions, category_id, apiKey) => {
  return axios.post(
    BASE_URL + "/questions_bulk",
    {
      category_id: category_id,
      questions: questions
    },
    {
      headers: { Authorization: `Bearer ${apiKey}` }
    }
  );
  // .then(response => {
  //   dispatch(AddQuestionsToStore(response.data));
  // })
  // .catch(error => {
  //   dispatch(addError(error.response));
  // });
};

export const AddQuestionsToStore = questions => dispatch => {
  // Adding drag_id to tips
  let q = questions.map(question => {
    return {
      ...question,
      tips: question.tips.map(tip => {
        return {
          ...tip,
          drag_id: tip.id + ""
        };
      })
    };
  });
  dispatch({
    type: C.ADD_QUESTION,
    payload: q
  });
};
export const setCBQuestion = questions => dispatch => {
  dispatch({
    type: C.SET_CB_QUESTIONS,
    payload: questions
  });
};
export const addQuestion = (id, text, tip, category_id) => (
  dispatch,
  getState
) => {
  axios
    .post(
      BASE_URL + "/questions",
      {
        category_id: category_id,
        text: text,
        tip_text: tip
      },
      {
        headers: { Authorization: `Bearer ${getState().apiKey}` }
      }
    )
    .then(response => {
      let question = {
        ...response.data,
        tips: response.data.tips.map(tip => {
          return { ...tip, drag_id: tip.id + "" };
        })
      };
      dispatch({
        type: C.ADD_QUESTION,
        payload: [question]
      });
    })
    .catch(error => {
      dispatch(addError(error.response));
    });
};
export const updateQuestion = (question_id, questionText) => (
  dispatch,
  getState
) => {
  axios
    .post(
      BASE_URL + "/questions/" + question_id,
      {
        text: questionText
      },
      {
        headers: { Authorization: `Bearer ${getState().apiKey}` }
      }
    )
    .then(response => {
      let question = {
        ...response.data,
        tips: response.data.tips.map(tip => {
          return { ...tip, drag_id: tip.id + "" };
        })
      };
      dispatch({
        type: C.UPDATE_QUESTION,
        payload: question
      });
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};

export const updateQuestionSelection = question => dispatch => {
  dispatch({
    type: C.UPDATE_QUESTION,
    payload: question
  });
};
export const addDomainResource = (
  id,
  name,
  url,
  description,
  category_id
) => dispatch => {
  axios
    .post(BASE_URL + "/resources", {
      name: name,
      url: url,
      category_id: category_id,
      description: description
    })
    .then(response => {
      dispatch({
        type: C.ADD_DOMAIN_RESOURCE,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};
export const removeDomainResource = id => dispatch => {
  axios
    .delete(BASE_URL + "/resources/" + id)
    .then(response => {
      if (response.status === 204) {
        dispatch({
          type: C.REMOVE_DOMAIN_RESOURCE,
          payload: id
        });
      }
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};

export const removeQuestion = id => (dispatch, getState) => {
  axios
    .delete(BASE_URL + "/question/" + id, {
      headers: { Authorization: `Bearer ${getState().apiKey}` }
    })
    .then(response => {
      if (response.status === 204) {
        dispatch({
          type: C.REMOVE_QUESTION,
          payload: id
        });
      }
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};

export const removeTip = tip => (dispatch, getState) => {
  axios
    .delete(BASE_URL + "/tip/" + tip.id, {
      headers: { Authorization: `Bearer ${getState().apiKey}` }
    })
    .then(response => {
      if (response.status === 204) {
        dispatch({
          type: C.REMOVE_TIP,
          payload: tip
        });
      }
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};
export const addTip = (topic_id, tip_text) => (dispatch, getState) => {
  axios
    .post(
      BASE_URL + "/tip",
      {
        topic_id: topic_id,
        tip_text: tip_text
      },
      {
        headers: { Authorization: `Bearer ${getState().apiKey}` }
      }
    )
    .then(response => {
      if (response.status === 201) {
        dispatch({
          type: C.ADD_TIP,
          payload: response.data
        });
      }
    })
    .catch(error => console.log(error.response.data));
};

export const updateTip = (id, text) => (dispatch, getState) => {
  axios
    .post(
      BASE_URL + "/tip/" + id,
      {
        text: text
      },
      {
        headers: { Authorization: `Bearer ${getState().apiKey}` }
      }
    )
    .then(response => {
      dispatch({
        type: C.UPDATE_TIP,
        payload: response.data
      });
    })
    .catch(error => {
      console.log(error.response);
      dispatch(addError(error.message));
    });
};
export const setRowsPerPage = rowsPerPage => (dispatch, getState) => {
  let prefs = getState().userPrefs;
  dispatch({
    type: C.SET_PREFS,
    payload: {
      ...prefs,
      rowsPerPage: rowsPerPage
    }
  });
};
