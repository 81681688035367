import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { MuiTheme } from "assets/theme/MuiTheme";
import { ThemeProvider } from "@material-ui/styles";
//import AuthLayout from "layouts/Auth.jsx";
//import AdminLayout from "layouts/Admin.jsx";
//import UserLayout from "layouts/User.jsx";
import appReducer from "./store/reducers";
//import initialState from "./store/initialState.json";
import { createStore, applyMiddleware } from "redux";
import initialState from "./store/initialState.json";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import Cookies from "universal-cookie";
import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import "assets/css/scrollBar.css";
import { getBorders } from "store/Actions";
import C from "./store/constants";

const store = applyMiddleware(thunk)(createStore)(
  appReducer,
  initialState
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const hist = createBrowserHistory();

const AuthLayout = lazy(() => import("./layouts/Auth"));
const AdminLayout = lazy(() => import("./layouts/Admin"));
const UserLayout = lazy(() => import("./layouts/User"));

// const hist = createBrowserHistory({
//   basename: "/app"
// });
//in json  "homepage": "http://35.190.159.138/development/build/",
window.store = store;
const cookies = new Cookies();
if (cookies.get("apiKey") !== undefined && cookies.get("user") !== undefined) {
  store.dispatch({
    type: C.SET_API_KEY,
    payload: cookies.get("apiKey")
  });
  store.dispatch({
    type: C.SET_USER,
    payload: cookies.get("user")
  });
}
store.dispatch(getBorders());

ReactDOM.render(
  <ThemeProvider theme={MuiTheme}>
    <Provider store={store}>
      <Router history={hist}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <Route path="/admin" component={AdminLayout} />
            <Route path="/" component={UserLayout} />
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
