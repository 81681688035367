import { createMuiTheme } from "@material-ui/core/styles";

export const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#01BDC8"
    },
    secondary: {
      main: "#4caf50"
    },
    info: {
      main: "#4caf50"
    },
    warning: {
      main: "#ff9800"
    },
    success: {
      main: "#4caf50"
    },
    error: {
      main: "#ef5350"
    }
  }
});
